import React from "react"
import Layout from "../components/Layout"

export default function Home() {
  return (
    <Layout>
      <section>
        <div>
          <h2>Curecall</h2>
          <h3>Gatsby</h3>
        </div>
      </section>
    </Layout>
  )
}